import { applyMiddleware, compose, createStore } from 'redux';

import firebase from '../config';
import middlewares from './middleware';
import { reduxFirestore } from 'redux-firestore'
import { responsiveStoreEnhancer } from 'redux-responsive';
import { rootReducer } from './reducers';

// service workers
const composeEnhancers = typeof window !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export default function configureStore() {
  const createStoreWithDevTools = window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore;

  const createStoreWithMiddlewares = applyMiddleware(...middlewares)(createStoreWithDevTools);

  return createStoreWithMiddlewares(
    rootReducer()
  );
}

export const store = createStore(
  rootReducer,
  composeEnhancers(
    responsiveStoreEnhancer,
    applyMiddleware(...middlewares),
    reduxFirestore(firebase)
  )
);
