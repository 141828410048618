import { cloneDeep, compact, find, identity, isArray, isEmpty, isNull, isUndefined, join, keys, map, merge, omit, omitBy, orderBy, pickBy, split, spread, toString, union, uniq } from 'lodash'
import { createRoot, expandToNode, fixDuplicateIndexes, getNodeById, getPageDataAsObjFromNode, update } from '../../screens/Sitemap/utils/app';
// modular 9
import { getBlob, getStorage, ref } from "firebase/storage";
import { getDecodedEmail, getInUserFlow, getIsUserFlowLinkedToSitemap, getSitemap, getUserFlow, getUserId, sendHubspotCustomEvent } from '../../helpers';

import { ascending } from 'd3';
import { chain } from '../../helpers/chain.js';
import { cleanPageSectionDataForFirestore } from '../../screens/Sitemap/app/canvas/utils/page-sections/helpers';
import copy from "fast-copy";
import deepmerge from 'deepmerge';
import { exportThumb } from '../../screens/Editor/Toolbar/Export/utils/thumb';
import { getCanEditInEditor } from '../../screens/Editor/Navbar/helpers';
import { getCustomCover } from '../../screens/Sitemap/app/canvas/utils/helpers';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';
import { getPalletteColors } from './editor-actions';
import { hideCommentsPopover } from '../../screens/Sitemap/comments/helpers.jsx';
import model from '../reducers/model';
import { loaded_wireframes as pageSectionsWireframesLoaded } from '../../screens/Sitemap/app/canvas/components/page-sections';
import queryString from 'query-string';
import { render as renderPages } from '../../screens/Sitemap/app/canvas/render';
import { render as renderUserFlow } from '../../screens/Sitemap/user-flows/render'
import { resetMouseoverListeners } from '../../screens/Sitemap/app/canvas/utils/listeners';
import { searchTree } from '../../screens/Editor/Navbar/Search/helpers';
import { store } from '../../store';
import { toggleUserFlowSymbolButtons } from './flow-actions';

const render = () => {
  const inUserFlow = getInUserFlow()
  !inUserFlow ? renderPages() : renderUserFlow()
}

export const initSitemap = (doc, { forUserFlow } = {}) => {
  return (dispatch, getState) => {
    const firestore = getFirestore()
    const sitemapId = doc.id
    dispatch({
      type: 'INIT_SITEMAP',
      meta: {
        ...doc,
        type: 'sitemap',
        format: getSitemapFormat(sitemapId),
        showCovers: getShowCovers(sitemapId)
      },
      async payload() {
        if (forUserFlow) {
          // console.log(`Getting for User Flow - Sitemap ID: ${sitemapId}`)
          const sitemapDoc = await firestore.doc(`sitemaps/${sitemapId}`).get();
          if (sitemapDoc?.exists) {
            return { id: sitemapId, ...sitemapDoc.data(), type: 'sitemap' };
          } else {
            throw new Error('not-found');
          }
        }
        // amplitude tracking
        // amplitude.getInstance().logEvent('VIEWED_SITEMAP', { id: sitemapId });
      },
    }).then(() => {
      // get website sections (which calls init pages doc, we need to make sure website sections is called first)
      const { website_sections, sections, covers, comments /*, scheduled_screenshots */ } = getDataDocsFromFirestoreListener({ sitemap: { id: sitemapId } });
      // get website sections
      setTimeout(() => {
        dispatch(initSitemapWebsiteSectionsDoc(website_sections));
      }, 50)
      // get sections
      setTimeout(() => {
        dispatch(initSitemapSectionsDoc(sections));
      }, 50);
      // get covers
      setTimeout(() => {
        dispatch(initSitemapCoversDoc(covers));
      }, 50);
      // get comments
      setTimeout(() => {
        dispatch(initSitemapCommentsDoc(comments));
      }, 50);
      // get scheduled_screenshots
      /* setTimeout(() => {
        dispatch(initSitemapScheduledScreenshots(scheduled_screenshots));
      }, 50); */
      //
    }).then(() => {
      /* const flow = getUserFlow()
      const linkedPages = flow?.data?.nodes?.filter(d => d.page);
      if (!isEmpty(linkedPages)) {
        console.log('Resetting node heights...')
        dispatch(resetNodeHeights([...linkedPages].map(d => d.id), { update: true }));
      } else {
        console.log('No linked pages found...')
      } */
    }).catch(e => { console.error(e); return { error: e } });
  };
};

export const getDataDocsFromFirestoreListener = ({ sitemap, flow }) => {

  const collection = sitemap ? "sitemaps" : "user-flows";
  const docToUse = sitemap || flow || null

  const { firestore } = store.getState();
  const data = firestore.data?.[collection]?.[docToUse?.id]?.data;
  return data || {};

}

export const getDataDoc = async ({ doc, docData, sitemap, flow }) => {

  const collection = sitemap ? "sitemaps" : "user-flows";
  const docToUse = sitemap ? sitemap : flow;

  if (!docToUse) return;

  const firestore = getFirestore();
  const storage = getStorage();
  // key
  const key = doc === "elements" ? "elements" : doc === 'website_sections' ? 'data' : 'pages';
  // 
  let docFromFirestore = docData ? docData : await (async () => {
    try {
      const d = await firestore.doc(`${collection}/${docToUse?.id}/data/${doc}`).get();
      return d.exists ? d.data() : { [key]: {} };
    } catch (e) { console.error(key, e); return { [key]: {} } }
  })();
  //
  let data = docFromFirestore.file || doc === 'seo' ? await getDocDataFromStorage() : docFromFirestore[key];
  // from storage 
  async function getDocDataFromStorage() {
    try {
      const storageRef = ref(storage, `${collection}/${docToUse?.id}/${doc === 'seo' ? 'seo/pages' : `data/${doc}`}.json`)
      const blob = await getBlob(storageRef)
      let json = JSON.parse(await blob.text())
      return json;
    } catch (err) { if (doc !== 'seo') console.error(err); return {} }
  }
  // return
  return { ...docFromFirestore, [key]: data ? data : {} };
}

export const initSitemapPagesDoc = (docData) => {
  return (dispatch, getState) => {
    const state = getState();
    const { sitemap, editor, ui } = state;
    if (sitemap?.loaded) return; // only initialize once;
    // dispatch
    dispatch({
      type: 'INIT_SITEMAP_PAGES_DOC',
      async payload() {
        const doc = await getDataDoc({ doc: 'pages', docData, sitemap });
        let pages = doc.pages;
        // NEED THIS in case home-page somehow doesn't exist - check ticket from 1 Dec 22
        if (!pages.home) pages.home = { id: "home", name: "Home", parent: null };
        // convert page data to root
        const pageDataForRoot = chain(pages).map((obj, key) => { return { ...obj, id: key } }).value();
        const root = await createRoot(pageDataForRoot);
        // return
        return {
          doc,
          docs: {
            pages,
            filesystem: {
              ...sitemap?.docs.filesystem,
              pages: !doc.file ? 'firestore' : 'storage'
            }
          },
          data: { root }
        };
      },
    }).then(({ action }) => {
      dispatch(initSitemapSeoDoc()) // init seo doc
      const { doc } = action.payload;
      if (doc && doc.file) update(); // show pages
      return doc;
    }).then(() => {
      // trigger update sitemap thumbnail here as this is when sitemap is loaded
      setTimeout(() => {
        const inUserFlow = getInUserFlow()
        if (!inUserFlow) {
          if (!sitemap?.thumbnail?.images?.[ui.colorMode]) { dispatch(updateSitemapThumbnail()) }
        }
      }, 10000);
    }).catch(e => { console.error(e); return { error: e } });
  };
};

export const initSitemapWebsiteSectionsDoc = (docData) => {
  return (dispatch, getState) => {
    const { sitemap } = getState()
    dispatch({
      type: 'INIT_SITEMAP_WEBSITE_SECTIONS_DOC',
      async payload() {
        const doc = await getDataDoc({ doc: 'website_sections', docData, sitemap });
        return { docs: { website_sections: doc ? doc.data : {} } };
      }
    }).then(() => {
      const { pages } = getDataDocsFromFirestoreListener({ sitemap });
      // get pages
      dispatch(initSitemapPagesDoc(pages));
    });
  };
};

export const initSitemapCoversCollections = (collections) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'INIT_SITEMAP_COVERS_COLLECTIONS',
      collections
    })
  };
};

export const initSitemapCoversDoc = (docData) => {
  return (dispatch, getState) => {
    const sitemap = getSitemap()
    const device = localStorage.getItem(`${sitemap?.id}#device`) || (sitemap?.covers?.device || 'desktop');
    dispatch({
      type: 'INIT_SITEMAP_COVERS_DOC',
      meta: { device },
      async payload() {

        const doc = await getDataDoc({ doc: 'covers', docData, sitemap });
        var pages = mappedCoverDownloadUrlsToPages({ pages: doc.pages, sitemap });

        // return
        return {
          ...sitemap?.covers,
          device,
          pages: { ...pages, ...model.sitemap?.covers?.pages }, // need this otherwise 'default' cover key breaks
        };
      },
    })
      .then(() => { setTimeout(() => render(), 250); })
      .catch(e => {
        console.error(e);
        return { error: e };
      });
  };
};

export const initSitemapCommentsDoc = (docData) => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    const comments = !isEmpty(sitemap?.comments) ? sitemap?.comments : model.sitemap?.comments // stops flashing empty popover when adding first comment and no comment doc has been created yet
    dispatch({
      type: 'INIT_SITEMAP_COMMENTS_DOC',
      meta: { comments },
      async payload() {
        const doc = await getDataDoc({ doc: 'comments', docData, sitemap });
        return {
          comments: {
            ...comments,
            ...doc?.pages || {}
          }
        }; // merge default with saved comments
      },
    }).then(() => {
      const { sitemap } = getState();
      if (!sitemap?.data.root) return;
      /*** go to page comments if in query string now comments are loaded ***/
      const { page, comment } = queryString.parse(window.location.search);
      if (page) {
        var paths = searchTree(sitemap?.data.root, page);
        if (typeof (paths) !== "undefined") {
          return expandToNode(paths, { searched: true, comments: comment });
        }
      }
      /*** go to page comments if in query string now comments are loaded ***/
      update();
    }).catch(e => {
      console.error(e);
      return { error: e };
    });
  };
};

export const initSitemapSectionsDoc = (docData) => {
  return (dispatch, getState) => {
    const { sitemap } = getState()
    dispatch({
      type: 'INIT_SITEMAP_SECTIONS_DOC',
      async payload() {
        const doc = await getDataDoc({ doc: 'sections', docData, sitemap });
        // get obj
        const obj = convertPageSectionsDocDataToUpdateFormat(doc);
        // return
        return { docs: { sections: doc.pages }, data: { sections: obj } };
      }
    }).then(() => {
      if (docData) update()
    }); // need this otherwise page sections don't show when returning from revision history
  };
};

export const convertPageSectionsDocDataToUpdateFormat = (doc) => {
  const existingSectionsData = {}
  const obj = {};
  doc = typeof doc === 'object' ? doc : {}; // ensure doc is always an object (as page sections doc might not exist yet)
  doc = copy(doc); // copy so we don't overwrite existing data
  var pageIds = keys(doc.pages ? doc.pages : {});
  if (!isEmpty(pageIds)) {
    pageIds.forEach(pageId => {
      if (!existingSectionsData[pageId]) existingSectionsData[pageId] = [];
      if (!obj[pageId]) obj[pageId] = [];
      const sectionsData = doc.pages[pageId];
      const sectionIds = keys(sectionsData);
      sectionIds.forEach(sectionId => {
        // if data doesn't already exist for this page (only when a new section has been added and the sections doc doesn't exist in firestore yet)
        if (!isEmpty(existingSectionsData[pageId])) {
          const existingSection = existingSectionsData[pageId].find(s => s.id.toString() === sectionId.toString());
          if (existingSection) obj[pageId].push(existingSection);
        } else {
          obj[pageId].push({ id: sectionId, ...sectionsData[sectionId] })
        }
      });
    });
    // sort by index (and give index if doesn't exist)
    pageIds.forEach(pageId => obj[pageId].sort((a, b) => ascending(a.index, b.index)).map((section, i) => { section.index = i; return section; }));
  }
  // return
  return obj;
}

export const initSitemapSeoDoc = () => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    // dispatch
    dispatch({
      type: 'INIT_SITEMAP_SEO_DOC',
      async payload() {
        const seo = await getDataDoc({ doc: 'seo', sitemap });
        return seo;
      },
    }).catch(e => console.error(e))
  };
};

export const initSitemapScheduledScreenshots = (docData) => {
  return (dispatch, getState) => {
    const { sitemap } = getState()
    dispatch({
      type: 'INIT_SITEMAP_SCHEDULED_SCREENSHOTS_DOC',
      async payload() {
        const scheduled_screenshots = await getDataDoc({ doc: 'scheduled_screenshots', docData, sitemap });
        return scheduled_screenshots;
      }
    })
  };
};

export const getCollaboratorsData = async (sitemapDoc) => {
  const sitemap = getSitemap();
  if (!sitemapDoc.collaborators) return [];
  if (isArray(sitemapDoc.collaborators)) return sitemapDoc.collaborators; // collaborators data has already been put in correct format and into array
  const data = compact(await Promise.all(
    chain(sitemapDoc.collaborators)
      .map(async (collaborator, id) => {
        // invited user
        if (collaborator.invitedBy) {
          /* eslint-disable-next-line */
          const decodedEmail = getDecodedEmail(id);
          return {
            id: decodedEmail,
            access: collaborator.access,
            firstName: decodedEmail,
            email: decodedEmail, // 'Collaborator has not accepted invitation yet',
            invited: true,
            invitedAt: collaborator?.invitedAt
          }
        };
        // get user
        const user = await getUser(sitemap?.collaborators, id)
        return user ? { ...user, access: collaborator.access } : null
      })
      .value()
  ));

  async function getUser(collaborators, id) {
    const firestore = getFirestore();
    // look for user in existing sitemap collaborators array
    if (Array.isArray(collaborators)) {
      const user = find(collaborators, c => c.id === id)
      if (user) return { id, firstName: user.firstName, lastName: user.lastName, email: user.email, photoURL: user.photoURL }
    }
    // if doesn't exist, retrieve user from firestore// confirmed user
    const docUser = await firestore.doc(`users/${id}`).get();
    if (!docUser.exists) {
      return null // { id: "deleted", firstName: 'Deleted', lastName: 'User' }
    } else {
      const { firstName, lastName, email, photoURL } = docUser.data();
      return { id, firstName, lastName, email, photoURL }
    }
  }

  return data;
};

export const mappedCoverDownloadUrlsToPages = ({ pages, sitemap }) => {
  const firebase = getFirebase();
  const bucket = firebase.app().options.storageBucket;
  if (!isEmpty(pages)) {
    // clone pages
    pages = JSON.parse(JSON.stringify(pages));
    // 
    keys(pages).map(pageId => {
      var { devices } = pages[pageId];
      if (devices) {
        const keys = ['desktop', 'mobile', 'tablet'];
        keys.forEach(key => {
          var device = devices[key];
          if (device) {
            if (!device.token) return;
            // custom cover is pending
            if (device.token === 'capturing') {
              return pages[pageId].devices[key] = {
                ...device, startedAt: device.startedAt && Object.prototype.hasOwnProperty.call(device, "toDate") ? device.startedAt.toDate() : new Date()
              }
            };
            // has custom cover - get download urls
            pages[pageId].devices[key] = {
              ...device,
              thumbDownloadURL: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(
                `sitemaps/${sitemap?.id}/covers/${pageId}/${key}/thumb.jpg`
              )}?alt=media&token=${device.token}${device.generation ? `&generation=${device.generation}` : ''}`,
              downloadURL: `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(
                `sitemaps/${sitemap?.id}/covers/${pageId}/${key}/cover.jpg`
              )}?alt=media&token=${device.token}`
            };
          }
        });
      }
      return pageId;
    });
  };
  // if pages object doesn't exist
  if (isEmpty(pages)) pages = model.sitemap?.covers.pages;
  return pages;
}

export const switchCoversDevice = device => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    // set device in local storage
    localStorage.setItem(`${sitemap?.id}#device`, device);
    // dispatch
    dispatch({ type: 'SWITCH_COVERS_DEVICE', device });
    // render
    render()
  };
};

function getSitemapFormat(sitemapId) {
  var format = localStorage.getItem(`${sitemapId}#format`);
  if (!format) {
    localStorage.setItem(`${sitemapId}#format`, 'tree-vertical');
    return 'tree-vertical';
  }
  return format;
}

function getShowCovers(sitemapId) {
  const sitemap = getSitemap()
  // return if already set (by shared URL query params)
  if (typeof sitemap.showCovers == "boolean") return sitemap.showCovers
  // continue local storage check
  var prevChoice = localStorage.getItem(`${sitemapId}#showCovers`);
  if (!prevChoice) {
    localStorage.setItem(`${sitemapId}#showCovers`, true);
    return true;
  }
  return prevChoice === 'true';
}

export const setFormat = format => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_FORMAT', format });
    // unset loaded fireframes
    keys(pageSectionsWireframesLoaded).forEach(id => pageSectionsWireframesLoaded[id] = {});
    // update
    update();
  };
};

export const setRoot = root => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_ROOT', root });
    hideCommentsPopover(); // hide comment popover if showing
    update();
  };
};

export const initSection = section => {
  return (dispatch, getState) => {
    if (section.id) {
      dispatch({ type: 'INIT_SECTION', section });
      dispatch(addSubfolderToTabs(section));
      hideCommentsPopover(); // hide comment popover if showing
      update();
    }
  };
};

export const setSection = section => {
  return (dispatch, getState) => {
    if (section.id) {
      dispatch({ type: 'SET_SECTION', section });
      // amplitude.getInstance().logEvent('SET_SITEMAP_SECTION'); // amplitude tracking
      update();
    }
  };
};

export const addSubfolderToTabs = subfolderToAdd => {
  return (dispatch, getState) => {
    /*** set url ***/
    const { sf } = queryString.parse(window.location.search);
    const url = `${window.location.origin}${window.location.pathname}?sf=${toString(uniq(compact([...split(sf, ','), subfolderToAdd.id !== 'home' ? subfolderToAdd.id : null])))}`
    window.history.replaceState(null, '', url);
    /*** set url ***/
    dispatch({ type: 'ADD_SUBFOLDER_TO_TABS', subfolderToAdd });
  };
};

export const removeSubfoldersFromTabs = subfoldersToClose => {
  return (dispatch, getState) => {
    const { sitemap } = getState()
    const subfolders = { ...sitemap?.ui.SubfoldersTabs }
    subfoldersToClose.forEach(id => delete subfolders[id]) // delete from current tabs
    /*** set url ***/
    const url = `${window.location.origin}${window.location.pathname}?sf=${toString(uniq(compact([...keys(subfolders).map(id => id !== 'home' ? id : null)])))}`
    window.history.replaceState(null, '', url);
    /*** set url ***/
    dispatch({ type: 'REMOVE_SUBFOLDERS_FROM_TABS', subfolders });
  };
};

// no more subfolder tabs
export const unsetSection = section => {
  return (dispatch, getState) => {
    dispatch({ type: 'UNSET_SECTION', section });
    window.history.pushState(null, '', `${window.location.origin}${window.location.pathname}`);
  };
};

export const setWebsiteSections = sections => {
  let shouldUpdate = false;
  return (dispatch, getState) => {
    const sitemap = getSitemap()
    const existingSectionsData = sitemap?.data?.website_sections;
    keys(sections).forEach(id => {
      let existingSection = existingSectionsData ? existingSectionsData[id] : {};
      let section = sections[id]
      // should update
      if (section && existingSection && (section.y !== existingSection.y)) {
        shouldUpdate = true;
      }
      // merge data with existing data
      sections[id] = { ...existingSection, ...section }
    })
    dispatch({ type: 'SET_WEBSITE_SECTIONS', sections });
    // should update?
    if (shouldUpdate) update(); // really important, responsible for immediate layout update for website_sections after main nodes layout has been updated
  };
};

export const setNodesAndLinks = ({ nodes, links }) => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    dispatch({ type: 'SET_NODES_AND_LINKS', nodes, links });
    if (isEmpty(sitemap?.data.nodes)) update(); // update on initial setting of nodes and links (this is to fix website section pages not having the correct y attributes when initially loading large sitemaps)

  };
};

export const setNodeHeights = (heights) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_NODE_HEIGHTS', heights });
  };
};

export const resetNodeHeights = (nodeIds, opts = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: 'RESET_NODE_HEIGHTS', nodeIds });
    // section changes should always include update as node height runs before calculating section heights
    // undo-redo for rename page shouldn't have update as needs to wait until undone/redone name is changed before updating node height
    if (opts.update) update();
  };
};

export const savePageChanges = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const state = getState();
    const { user, sitemap } = state;
    let changes = { ...sitemap?.changes.pages };
    if (changes.saving || isEmpty(changes.data)) return false;
    // are pages saved in firestore or storage
    const pagesSavedInFirestore = sitemap?.docs.filesystem['pages'] === 'firestore';
    // sort changes
    changes.data = orderBy(changes.data, 'id', 'asc');
    // so we know which changes to delete when saved to firestore successfully
    const changeIds = map(changes.data, 'id');
    // join changes into one array
    const data = spread(union)(map(changes.data, 'data'));
    /*** convert data for firestore ***/
    const pagesDoc = sitemap?.docs.pages;
    var pages = {};
    data.forEach(d => {
      var pageData = omitBy(omit(d, ['action', 'id', 'cover', 'comments']), isUndefined);
      const pageId = d.id;
      if (!pages[pageId]) pages[pageId] = {};
      // override if whole page was set to be deleted but has been redone to add the page again
      if ((pages[pageId]["r_"] === "FieldValue.delete")) pages[pageId] = null;
      // actions
      if (d.action === 'remove') {
        pages[pageId] = pagesSavedInFirestore ? firestore.FieldValue.delete() : null; // ensure deleted always happens
      } else {
        pages[pageId] = { ...pages[pageId], ...pageData }; // merge new changes into existing page changes
        // merge all page doc attributes into page changes (to stop issues where we lose name/url attributes on reorder)
        if (pagesDoc[pageId]) pages[pageId] = { ...pages[pageId], ...pagesDoc[pageId] };
      }
    });
    /*** convert data for firestore ***/
    // ensure only whitelisted keys are saved to firestore
    pages = whitelistPagesKeys(pages, { pagesSavedInFirestore });
    //
    dispatch({
      type: 'SAVE_PAGE_CHANGES',
      async payload() {
        /*** save data ***/
        if (!isEmpty(pages)) {
          if (import.meta.env.PROD) {
            await firestore.doc(`sitemaps/${sitemap?.id}/data/pages`).set({ pages, lastEdit: user.id, updatedAt: new Date() }, { merge: true });
          }
        }
        /*** save data ***/
      }
    }).then(() => {
      dispatch(clearSavedPageChanges(changeIds));
    }).then(() => {
      setTimeout(() => dispatch(updateSitemapThumbnail()), 1000);
    }).catch(e => {
      console.error(e);
      return { error: e };
    });
  }
};

export const saveWebsiteSectionChanges = () => {
  return async (dispatch, getState) => {
    const firestore = getFirestore()
    const { user, sitemap } = getState();
    const changes = sitemap?.changes.website_sections;
    if (changes.saving || isEmpty(changes.data)) return false;
    // so we know which changes to delete when saved to firestore successfully
    const changeIds = map(changes.data, 'id');
    // join changes into one array
    const data = spread(union)(map(changes.data, 'data'));
    /*** convert data for firestore ***/
    var website_sections = {};
    data.forEach(d => {
      var sectionData = omitBy(omit(d, ['action', 'id']), isUndefined);
      const sectionId = d.id;
      if (!website_sections[sectionId]) website_sections[sectionId] = {};
      // override if whole page was set to be deleted but has been redone to add the page again
      if (website_sections[sectionId]["r_"] === "FieldValue.delete") website_sections[sectionId] = {};
      // actions
      if (d.action === 'website-section-remove') {
        website_sections[sectionId] = firestore.FieldValue.delete(); // ensure deleted always happens
      } else {
        website_sections[sectionId] = { ...website_sections[sectionId], ...sectionData }; // existing page changes, merge new changes
      }
    });
    /*** convert data for firestore ***/
    // ensure only whitelisted keys are saved to firestore
    website_sections = whitelistWebsiteSectionKeys(website_sections);
    // dispatch
    dispatch({
      type: 'SAVE_WEBSITE_SECTION_CHANGES',
      async payload() {
        /*** save to firestore ***/
        if (!isEmpty(website_sections)) {
          // if (import.meta.env.PROD) {
          await firestore.doc(`sitemaps/${sitemap?.id}/data/website_sections`).set({ data: website_sections, lastEdit: user.id, updatedAt: new Date() }, { merge: true });
          // }
        }
        /*** save to firestore ***/
        return { website_sections }
      }
    }).then(() => {
      dispatch(clearSavedWebsiteSectionChanges(changeIds));
    }).catch(e => {
      console.error(e);
      return { error: e }
    });
  }
};

export const savePageSectionChanges = () => {
  return async (dispatch, getState) => {
    const firestore = getFirestore();
    const { user, sitemap } = getState();
    const changes = sitemap?.changes.sections;
    if (changes.saving || isEmpty(changes.data)) return false;
    // so we know which changes to delete when saved to firestore successfully
    const changeIds = map(changes.data, 'id');
    //
    dispatch({
      type: 'SAVE_PAGE_SECTION_CHANGES',
      async payload() {
        // join changes into one array
        const data = spread(union)(map(changes.data, 'data'));
        /*** convert data for firestore ***/
        var pages = {};
        data.forEach(d => {
          const { action, pageId, section } = d;
          if (!section) {
            /*** pages deleted / cloned ***/
            pages = { ...pages, ...d }; // data has already been formatted for firestore, merge changes
            /*** pages deleted / cloned ***/
          } else {
            /*** individual section in a page changed ***/
            if (!pages[pageId]) pages[pageId] = {};
            if (!pages[pageId][section.id]) pages[pageId][section.id] = {};
            // override if whole section was set to be deleted but has been redone to add the section again
            if (pages[pageId][section.id]["r_"] === "FieldValue.delete") pages[pageId][section.id] = {};
            // get cleaned section data
            var sectionData = cleanPageSectionDataForFirestore(section);
            // actions
            if (action === 'page-section-remove') {
              pages[pageId][section.id] = firestore.FieldValue.delete(); // ensure deleted always happens
            } else if (action === 'page-section-color') {
              pages[pageId][section.id] = { ...pages[pageId][section.id], ...sectionData, color: sectionData.color === 'delete' ? firestore.FieldValue.delete() : sectionData.color }; // delete color if undefined
            } else if (action === 'page-section-wireframe') {
              pages[pageId][section.id] = { ...pages[pageId][section.id], ...sectionData, wireframe: sectionData.wireframe === 'delete' ? firestore.FieldValue.delete() : sectionData.wireframe }; // delete wireframe if undefined
            } else {
              pages[pageId][section.id] = { ...pages[pageId][section.id], ...sectionData }; // merge new changes
            };
            /*** individual section in a page changed ***/
          };
        });
        /*** convert data for firestore ***/
        // ensure only whitelisted keys are saved to firestore
        pages = whitelistPageSectionKeys(pages);
        // don't allow any empty saves (clear changes so we DON'T count as error and stop the autosave)
        if (isEmpty(pages)) return dispatch(clearSavedPageSectionChanges(changeIds));
        /*** save data ***/
        // if (import.meta.env.PROD) {
        await firestore.doc(`sitemaps/${sitemap?.id}/data/sections`).set({ pages, lastEdit: user.id, updatedAt: new Date() }, { merge: true });
        // }
        /*** save data ***/
      }
    }).then(() => {
      dispatch(updatePageSectionsFromFirebaseListener());
      dispatch(clearSavedPageSectionChanges(changeIds));
    }).then(() => {
      setTimeout(() => dispatch(updateSitemapThumbnail()), 1000);
    }).catch(e => {
      console.error(e)
      dispatch(clearSavedPageSectionChanges(changeIds)); // TEMP FOR TESTING - SHOULD OVERRIDE WITH 4 RETRIES LIKE PAGES
      return { error: e }
    });
  }
};

export const updatePageSectionsFromFirebaseListener = () => {
  return (dispatch, getState) => {
    const { firestore, sitemap } = getState();
    const pages = firestore.data.sitemaps?.[sitemap?.id]?.data?.sections?.pages || {};
    if (!isEmpty(pages)) dispatch({ type: 'UPDATE_PAGE_SECTIONS_FROM_FIREBASE_LISTENER', pages });
  };
};

export const saveCoverChanges = () => {
  return async (dispatch, getState) => {
    const firestore = getFirestore()
    const { user, sitemap } = getState();
    const changes = sitemap?.changes.covers;
    if (changes.saving || isEmpty(changes.data)) return false;
    // so we know which changes to delete when saved to firestore successfully
    const changeIds = map(changes.data, 'id');
    //
    dispatch({
      type: 'SAVE_COVERS_CHANGES',
      async payload() {
        // join changes into one array
        const pages = merge.apply(null, [{}].concat(spread(union)(map(changes.data, 'data'))));
        // don't allow any empty saves (clear changes so we DON'T count as error and stop the autosave)
        if (isEmpty(pages)) return dispatch(clearSavedCoversChanges(changeIds));
        if (keys(pages).length === 1 && keys(pages[0]) === 'default') return dispatch(clearSavedCoversChanges(changeIds)); // fail 
        /*** save covers data ***/
        // if (import.meta.env.PROD) {
        await firestore.doc(`sitemaps/${sitemap?.id}/data/covers`).set({ pages, lastEdit: user.id, updatedAt: new Date() }, { merge: true });
        // }
        /*** save page data ***/
      }
    }).then(() => {
      dispatch(clearSavedCoversChanges(changeIds));
    }).then(() => {
      setTimeout(() => dispatch(updateSitemapThumbnail()), 1000);
    }).catch(e => {
      return { error: e };
    });
  }
}

export const saveCommentsChanges = () => {
  return async (dispatch, getState) => {
    const firestore = getFirestore();
    const { user, sitemap } = getState();
    const changes = sitemap?.changes.comments;
    if (changes.saving || isEmpty(changes.data)) return false;
    // so we know which changes to delete when saved to firestore successfully
    const changeIds = map(changes.data, 'id');
    //
    dispatch({
      type: 'SAVE_COMMENTS_CHANGES',
      async payload() {
        // join changes into one array
        const pages = merge.apply(null, [{}].concat(spread(union)(map(changes.data, 'data'))));
        // don't allow any empty saves (clear changes so we DON'T count as error and stop the autosave)
        if (isEmpty(pages)) return dispatch(clearSavedCommentsChanges(changeIds));
        /*** save page data ***/
        /* if (import.meta.env.PROD) */ await firestore.doc(`sitemaps/${sitemap?.id}/data/comments`).set({ pages, lastEdit: user.id, updatedAt: new Date() }, { merge: true });
        /*** save page data ***/
      }
    }).then(() => {
      dispatch(clearSavedCommentsChanges(changeIds));
    }).catch(e => {
      console.error(e);
      return { error: e };
    });
  }
};

/*** UNDO/REDO USER CHANGES ***/
export const undoUserChange = () => {
  return (dispatch, getState) => {
    const state = getState();
    //
    const undo = cloneDeep(state.sitemap?.history.undo);
    const redo = cloneDeep(state.sitemap?.history.redo);
    //
    const removedItem = undo.pop();
    //
    if (removedItem) redo.push(removedItem);
    //
    dispatch({ type: 'UNDO_USER_CHANGE', undo, redo });
  };
};

export const redoUserChange = props => {
  return (dispatch, getState) => {
    const state = getState();
    //
    const undo = cloneDeep(state.sitemap?.history.undo);
    const redo = cloneDeep(state.sitemap?.history.redo);
    //
    const undoedItem = redo.pop();
    //
    if (undoedItem) undo.push(undoedItem);
    //
    dispatch({ type: 'REDO_USER_CHANGE', undo, redo });
    //
  };
};
/*** UNDO/REDO USER CHANGES ***/

export const showCovers = () => {
  return async dispatch => {
    dispatch({ type: 'SHOW_COVERS' });
    update();
  };
};

export const hideCovers = () => {
  return async dispatch => {
    dispatch({ type: 'HIDE_COVERS' });
    update();
  };
};

export const togglePageDrawer = ({ showing, node, editPageUrl, defaultIndex } = {}) => {
  return (dispatch, getState) => {

    const { sitemap } = getState();

    dispatch({
      type: 'TOGGLE_PAGE_DRAWER',
      showing: showing || !sitemap?.ui.PageDrawer.showing,
      editPageUrl: editPageUrl ? true : false,
      defaultIndex: defaultIndex || "covers",
      page: node || {},
    });

    // if (sitemap?.ui.PageButtons.showing) dispatch(togglePageButtons({ showing: false }))
    // if (flow.ui.SymbolButtons.showing) dispatch(toggleUserFlowSymbolButtons({ showing: false }))
  };
};

export const toggleCaptureDrawer = () => {
  return (dispatch, getState) => {
    const { sitemap, flow } = getState();
    dispatch({
      type: 'TOGGLE_CAPTURE_DRAWER',
      showing: !sitemap?.ui.CaptureDrawer.showing
    });
  };
};

export const toggleRecurringScreenshotsDrawer = ({ page } = {}) => {
  return (dispatch, getState) => {
    const { sitemap, flow } = getState();
    dispatch({
      type: 'TOGGLE_SCHEDULED_SCREENSHOTS_DRAWER',
      showing: !sitemap?.ui.RecurringScreenshotsDrawer.showing,
      page
    });
  };
};

/*** SELECT COVERS ***/
export const showSelectCoverModal = node => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    const { pages, device } = sitemap?.covers;
    const customCover = node.id ? getCustomCover(pages[node.id]) : null;
    const tab = customCover ? 'custom-cover' : 'covers';
    dispatch({
      type: 'SHOW_SELECT_COVER_MODAL',
      page: node.id,
      url: getURL(node.id),
      tab
    });
  };
};

export const changeCoverModalTab = tab => {
  return async dispatch => {
    dispatch({
      type: 'CHANGE_COVER_MODAL_TAB', tab
    });
  };
};

export const deleteCoverFromFirestore = async ({ firestore, sitemapId, page, device, user }) => {
  await firestore
    .doc(`sitemaps/${sitemapId}/data/covers`)
    .set(
      {
        pages: {
          [page]: {
            devices: {
              [device]: firestore.FieldValue.delete()
            },
          },
        },
        lastEdit: user.id,
      },
      { merge: true }
    );
}

export const captureCoverFromWebsite = props => {
  return async (dispatch, getState) => {
    const firebase = getFirebase()
    const firestore = getFirestore()
    const { sitemap, user } = getState()
    const { page, device, url, fullpage, lazyload, waitForFiveSeconds, hidden, httpAuth } = props;
    const inUserSitemap = sitemap?.id === user.id
    dispatch({
      type: 'CAPTURE_COVER_FROM_WEBSITE',
      async payload() {
        // send to GA
        const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `Screenshot Capture${inUserSitemap ? ': User' : ''}${!sitemap?.createdBy ? ': Anonymous' : ''}` } };
        window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
        // send event to Hubspot
        sendHubspotCustomEvent('captured_screenshot', { number_of_screenshots: 1 })
        /*** USER SITEMAP - doesn't save images to pages ***/
        /* if (inUserSitemap || !sitemap?.createdBy) {
          const res = await firebase.functions().httpsCallable('sitemaps-covers-getCoverFromWebsite')({ sitemap: sitemap?.id, page, url, device, fullpage: false, lazyload, waitForFiveSeconds, hidden, httpAuth, preview: true });
          if (res.data) {
            const { image } = res.data;
            return {
              pages: {
                [page]: {
                  devices: {
                    [device]: { preview: image }
                  },
                },
              }
            }
          }
          return;
        } */
        /*** USER SITEMAP - doesn't save images to pages ***/
        // add pending to covers doc in firestore
        await firestore
          .doc(`sitemaps/${sitemap?.id}/data/covers`)
          .set(
            {
              lastEdit: user.id,
              pages: {
                [props.page]: {
                  devices: {
                    [props.device]: { token: 'capturing', startedAt: new Date() }
                  },
                },
              }
            },
            { merge: true }
          );
        // get cover from website
        await firebase.functions().httpsCallable('sitemaps-covers-getCoverFromWebsite')({ sitemap: sitemap?.id, page, url, device, fullpage: inUserSitemap ? false : fullpage, lazyload, waitForFiveSeconds, hidden, httpAuth });
      },
    }).catch(async e => {
      // delete from firestore
      await deleteCoverFromFirestore({ firestore, sitemapId: sitemap?.id, page, device, user });
      return { error: e };
    }).finally(() => update());
  };
};

export const cancelCaptureCoverFromWebsite = props => {
  return async (dispatch, getState) => {
    const firestore = getFirestore();
    const { sitemap, user } = getState();
    const { page, device } = props;
    dispatch({
      type: 'CANCEL_CAPTURE_COVER_FROM_WEBSITE',
      async payload() {
        // delete from firestore
        await deleteCoverFromFirestore({ firestore, sitemapId: sitemap?.id, page, device, user });
      },
    })
  };
};

export const clearPreviewedScreenshotPage = ({ page, device }) => {
  return dispatch => {
    const pages = {
      [page]: {
        devices: {
          [device]: undefined
        }
      }
    }
    dispatch({ type: 'CLEAR_PREVIEWED_SCREENSHOT_PAGE', pages });
    update();
  };
};

export const saveCoverChange = props => {
  const { selected, setDefault, page, type } = props;
  return async (dispatch, getState) => {
    const firestore = getFirestore();
    const { id: sitemapId } = getState().sitemap;
    const { uid } = getState().firebase.auth;
    dispatch({
      type: 'SAVE_COVER_CHANGE',
      meta: props,
      async payload() {
        //
        const obj = {
          pages: {
            [page]: {
              [type]: selected,
            },
          },
          lastEdit: uid
        };
        if (setDefault) obj.default = selected;
        // save in firestore
        await firestore.doc(`sitemaps/${sitemapId}/data/covers`).set(obj, { merge: true });
        //
        return obj;
        //
      },
    }).catch(e => {
      console.error(e);
      return { error: e };
    });
  };
};

export const hideSelectCoverModal = () => {
  return async dispatch => {
    dispatch({ type: 'HIDE_SELECT_COVER_MODAL' });
  };
};

/*** SELECT COVERS ***/

/*** GET URL ***/
export const getURL = (nodeId) => {

  const sitemap = getSitemap();

  const { pages } = sitemap?.docs;
  const nodeFromPagesDoc = pages[nodeId];

  if (!nodeFromPagesDoc) return '';
  if (nodeFromPagesDoc.url) return decodeURIComponent(nodeFromPagesDoc.url);
  if (!sitemap?.domain) return '';

  /*** showtime ***/
  const subfolders = [];
  function getParentUrls(pageId) {
    if (pageId === 'home') return;
    const d = pages[pageId];
    if (d) {
      const subfolderName = cleanNameForGetURL(d.name);
      subfolders.unshift(subfolderName);
      // recurse
      if (d.parent) getParentUrls(d.parent);
    }
  }
  getParentUrls(nodeFromPagesDoc.parent);
  /*** showtime ***/

  let url = sitemap?.domain + join(subfolders, '') + cleanNameForGetURL(nodeFromPagesDoc.name);

  return decodeURIComponent(url);

};

function cleanNameForGetURL(name) {
  if (name === "Home") return "";
  let str = (name ? `/${name.replace(/\s/g, '-').toLowerCase()}` : '');
  str = str.replace('/?', '?'); // if there is a parameter in the URL, remove the trailing slash before it 
  return decodeURIComponent(str);
}
/*** GET URL ***/

export const showUpload = () => {
  return async dispatch => {
    dispatch({ type: 'SHOW_UPLOAD' });
  };
};

export const showCommentForm = mockupId => {
  return async dispatch => {
    dispatch({ type: 'SHOW_COMMENT_FORM', mockupId: mockupId });
  };
};

export const hideCommentForm = mockupId => {
  return async dispatch => {
    dispatch({ type: 'HIDE_COMMENT_FORM', mockupId: mockupId });
  };
};

export const mergePagesEdits = (data) => {

  return async (dispatch, getState) => {

    const { sitemap } = getState();
    const { section } = sitemap?.data;
    const inSubfolder = section ? true : false;
    // continue
    let pages = data.pages ? deepmerge(sitemap?.docs?.pages, data?.pages) : sitemap?.docs.pages;
    if (data.pages) keys(data.pages).forEach(key => data?.pages?.[key] === undefined ? delete pages[key] : {}); // remove undefined (deleted) pages
    /*** see if need to merge from storage first ***/
    if (data.storage) {
      const storage = getStorage();
      const pagesFileRef = ref(storage, `sitemaps/${sitemap?.id}/data/pages.json`);
      const blob = await getBlob(pagesFileRef)
      let pagesFileData = JSON.parse(await blob.text())
      /*** merge data from file with current doc data ***/
      pages = { ...pages, ...pagesFileData };
      /*** merge data from file with current doc data ***/
      dispatch({ type: 'MERGE_PAGES_EDITS', pages });
    };
    /*** see if need to merge from storage first ***/
    // root
    let pagesForRoot = chain(pages).map((obj, key) => { return { ...obj, id: key } }).value();
    pagesForRoot = fixDuplicateIndexes(pagesForRoot);
    const newRoot = await createRoot(pagesForRoot);
    store.dispatch(setRoot(newRoot));
    // subfolder
    if (inSubfolder) {
      const subfolderId = section.id;
      const nodeById = getNodeById(newRoot, subfolderId);
      const subfolderPages = chain(getPageDataAsObjFromNode(nodeById)).map((obj, id) => Object.assign({}, obj, { id })).value();
      const subfolderRoot = await createRoot(subfolderPages);
      store.dispatch(setSection(subfolderRoot));
    }
    // dispatch
    if (!data.storage) dispatch({ type: 'MERGE_PAGES_EDITS', pages });
    // update
    update();
  };
};

export const mergeWebsiteSectionsEdits = (data) => {
  return async (dispatch, getState) => {
    const { sitemap } = getState();
    let website_sections = data.website_sections ? merge({ ...sitemap?.docs.website_sections, ...data.website_sections }) : sitemap?.docs.website_sections;
    keys(data.website_sections).forEach(key => data.website_sections[key] === undefined ? delete website_sections[key] : {}); // remove undefined (deleted) sections
    // dispatch
    dispatch({ type: 'MERGE_WEBSITE_SECTIONS_EDITS', website_sections });
    // update
    update();
  };
};

export const mergePageSectionsEdits = ({ pages }) => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    // below is the same as initSitemapSectionsDoc
    /*** get current page sections data ***/
    const existingSectionsData = { ...sitemap?.data.page_sections };
    /*** get current page sections data ***/
    var obj = {};
    var pageIds = keys(pages);
    pageIds.forEach(pageId => {
      if (!existingSectionsData[pageId]) existingSectionsData[pageId] = [];
      if (!obj[pageId]) obj[pageId] = [];
      const sectionsData = pages[pageId];
      const sectionIds = keys(sectionsData);
      sectionIds.forEach(sectionId => obj[pageId].push({ id: sectionId, ...sectionsData[sectionId] }));
    });
    // sort by index (and give index if doesn't exist)
    pageIds.forEach(pageId => obj[pageId].sort((a, b) => ascending(a.index, b.index)).map((section, i) => { section.index = i; return section; }));
    // dispatch
    dispatch({ type: 'MERGE_PAGE_SECTIONS_EDITS', sections: obj });
    // update pages from firebase listener
    dispatch(updatePageSectionsFromFirebaseListener());
    // update
    update();
  };
};


export const mergeCoversEdits = (data) => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    var pages = mappedCoverDownloadUrlsToPages({ pages: data.pages, sitemap: sitemap });
    dispatch({ type: 'MERGE_COVERS_EDITS', pages });
    update();
  };
};

export const mergeCommentsEdits = (data) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'MERGE_COMMENTS_EDITS', pages: data.pages });
    update();
  };
};

export const mergeSeoEdits = (data) => {
  return async (dispatch, getState) => {
    if (!data) return;
    if (isEmpty(data.pages)) return;
    dispatch({ type: 'MERGE_SEO_EDITS', seo: data.pages });
    update();
  };
};

export const mergeRecurringScreenshotsEdits = (data) => {
  return async (dispatch, getState) => {
    if (!data) return;
    dispatch({ type: 'MERGE_scheduled_screenshots_EDITS', scheduled_screenshots: data });
    update();
  };
};

export const togglePageCommentsDrawer = () => {
  return (dispatch, getState) => {
    const { sitemap } = getState();
    const showing = !sitemap?.ui.PageCommentsDrawer.showing
    dispatch({ type: 'TOGGLE_PAGE_COMMENTS_DRAWER', showing });
  };
};

export const showPageCommentsPopover = props => {
  const { page, node, offset } = props;
  return (dispatch, getState) => {
    dispatch({ type: 'SHOW_PAGE_COMMENTS_POPOVER', page, node, offset });
  };
};

export const hidePageCommentsPopover = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'HIDE_PAGE_COMMENTS_POPOVER' });
  };
};

const pages = {
  home: {
    name: 'Home',
    parent: null,
  },
  gukpmzfaab: {
    name: 'Page',
    parent: 'home',
    index: 0,
  },
  gukpmzfbab: {
    name: 'Page',
    parent: 'home',
    index: 1,
  },
  gukpmzfcab: {
    name: 'Page',
    parent: 'home',
    index: 2,
  },
};

export const showShareSitemapModal = () => {
  return (dispatch, getState) => {
    // amplitude.getInstance().logEvent('OPENED_SHARE_SITEMAP_MODAL');
    dispatch({ type: 'SHOW_SHARE_SITEMAP_MODAL' })
  }
}

export const hideShareSitemapModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'HIDE_SHARE_SITEMAP_MODAL' })
  }
}

export const showOverflowModal = (parent, pages) => {
  return (dispatch, getState) => {
    // amplitude.getInstance().logEvent('OPENED_SITEMAP_OVERFLOW_MODAL');
    dispatch({ type: 'SHOW_OVERFLOW_MODAL', parent, pages })
  }
}

export const hideOverflowModal = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'HIDE_OVERFLOW_MODAL' })
  }
}


/*** FULLSCREEN COVER DRAWER ***/
export const showFullscreenCoverDrawer = ({ page, preview, device }) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SHOW_FULLSCREEN_COVER_DRAWER', page, preview, device });
    // amplitude.getInstance().logEvent('OPENED_SITEMAP_FULLSCREEN_COVER_DRAWER');
  }
}

export const hideFullscreenCoverDrawer = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'HIDE_FULLSCREEN_COVER_DRAWER' })
  }
}
/*** FULLSCREEN COVER DRAWER ***/

export const toggleContextMenuDropdown = (showing, { node, offset }) => {
  return (dispatch, getState) => {
    const { sitemap, flow } = getState()
    dispatch({ type: 'TOGGLE_CONTEXTMENU_DROPDOWN', showing, node, offset })
    if (sitemap?.ui.PageButtons.showing) dispatch(togglePageButtons({ showing: false }))
    if (flow.ui.SymbolButtons.showing) dispatch(toggleUserFlowSymbolButtons({ showing: false }))
  }
}

export const toggleExportPDFDrawer = ({ showing }) => {
  return (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_EXPORT_PDF_DRAWER', showing })
  }
}

/*** AUTO-SAVE ***/

/*** ADD PAGE CHANGE ***/
export const addPageChange = props => {
  const { change, history, PageDrawer } = props;
  return async (dispatch, getState) => {
    const state = getState();
    // dispatch
    dispatch({ type: 'ADD_PAGE_CHANGE', change, history, PageDrawer });
    //
    /* const { root, section } = state.sitemap?.data;
    // merge changes into root if in section (subfolder section)
    if (section) {
      // section data
      const sectionData = getPageDataAsObjFromNode(section);
      // root data
      const rootData = getPageDataAsObjFromNode(root);
      // ensure that section data root includes a parent (so we don't get multiple roots)
      sectionData[section.id].parent = rootData[section.id].parent;
      // merge together
      const merged = assign(rootData, sectionData);
      // get pages for root
      const pages = chain(merged).map((obj, key) => { obj.id = key; return obj; }).value();
      // create & set root in redux
      dispatch(setRoot(await createRoot(pages)));
    }
    // re-render!
    render() */
  };
};
/*** ADD PAGE CHANGE ***/

/*** ADD WEBSITE SECTIONS CHANGE ***/
export const addWebsiteSectionChange = props => {
  const { change, history, PageDrawer } = props;
  return async (dispatch) => {
    dispatch({ type: 'ADD_WEBSITE_SECTION_CHANGE', change, history, PageDrawer });
  };
};
/*** ADD WEBSITE SECTIONS CHANGE ***/

/*** ADD PAGE SECTION CHANGE ***/
export const addPageSectionChange = props => {
  const { change, history } = props;
  return async (dispatch, getState) => {
    // dispatch
    dispatch({ type: 'ADD_PAGE_SECTION_CHANGE', change, history });
  };
};
/*** ADD PAGE SECTION CHANGE ***/

/*** ADD COVERS CHANGE ***/
export const addCoversChange = props => {
  const { change, covers } = props;
  return async (dispatch, getState) => {
    dispatch({ type: 'ADD_COVERS_CHANGE', change, covers });
    // deleting - update to update icon/text from page
    update();
    // render (for user flows)
    render()
  }
};
/*** ADD COVERS CHANGE ***/

/*** ADD COMMENTS CHANGE ***/
export const addCommentsChange = props => {
  const { change, comment } = props;
  return async (dispatch, getState) => {
    dispatch({ type: 'ADD_COMMENTS_CHANGE', change, comment });
    // deleting - update to update icon/text from page
    update({ noSimulation: true });
  }
};
/*** ADD COMMENTS CHANGE ***/

/*** CLEAR AUTO-SAVE CHANGES ***/
export const clearSavedPageChanges = savedChanges => {
  return async dispatch => {
    dispatch({ type: 'CLEAR_SAVED_PAGE_CHANGES', savedChanges });
  };
};
export const clearSavedWebsiteSectionChanges = savedChanges => {
  return async dispatch => {
    dispatch({ type: 'CLEAR_SAVED_WEBSITE_SECTION_CHANGES', savedChanges });
  };
};

export const clearSavedPageSectionChanges = savedChanges => {
  return async dispatch => {
    dispatch({ type: 'CLEAR_SAVED_PAGE_SECTION_CHANGES', savedChanges });
  };
};
export const clearSavedCoversChanges = savedChanges => {
  return async dispatch => {
    dispatch({ type: 'CLEAR_SAVED_COVERS_CHANGES', savedChanges });
  };
};
export const clearSavedCommentsChanges = savedChanges => {
  return async dispatch => {
    dispatch({ type: 'CLEAR_SAVED_COMMENTS_CHANGES', savedChanges });
  };
};
/*** AUTO-SAVE ***/

export const mergeSitemapDocEdits = (changes) => {
  const { changedUpdatedAt, changedUpdatedBy, changedName, changedDomain, changedDescription, changedCollaborators, changedFlows } = changes;
  return (dispatch, getState) => {
    var sitemap = { ...getState().sitemap };
    if (changedUpdatedAt) sitemap.updatedAt = changedUpdatedAt;
    if (changedUpdatedBy) sitemap.updatedBy = changedUpdatedBy;
    if (changedName) sitemap.name = changedName;
    if (changedDomain) sitemap.domain = changedDomain;
    if (changedCollaborators) sitemap.collaborators = changedCollaborators;
    if (changedDescription) sitemap.description = changedDescription;
    if (!isUndefined(changedFlows)) sitemap.flows = changedFlows;
    dispatch({ type: 'MERGE_SITEMAP_DOC_EDITS', sitemap });
  };
};

/*** colors ***/
export const changeSitemapPallette = pallette => {
  return async (dispatch, getState) => {
    const { sitemap } = getState();
    pallette = { ...sitemap?.pallette, ...pallette, colors: getPalletteColors(pallette.header) };
    dispatch({ type: 'CHANGE_SITEMAP_PALLETTE', pallette });
    update();
  };
};

/*** colors ***/

export const toggleSitemapNotificationsDropdown = showing => {
  return async (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_SITEMAP_NOTIFICATIONS_DROPDOWN', showing });
  }
}

const transformPageSectionsDocDataForSaveSitemapAfterSignup = (sections) => {
  const firestorePagesObj = {};
  var pageIds = keys(sections);
  pageIds.forEach(pageId => {
    const sectionData = sections[pageId];
    if (!isEmpty(sectionData)) {
      firestorePagesObj[pageId] = {};
      sectionData.forEach(section => {
        const { id, index, title, wireframe, color } = section;
        firestorePagesObj[pageId][id] = pickBy({ index, title, wireframe, color }, identity);
      })
    }
  })
  return firestorePagesObj
}

/*** PAGE SECTIONS ***/
export const updatePageSectionsData = (sections) => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_PAGE_SECTIONS_DATA', sections });
    update();
  }
};

export const togglePageSectionsOptionsPopover = ({ showing, showUpgradeMessaging, offset, node, section }) => {
  return (dispatch, getState) => {
    if (showing === false) {
      // endRenamePageSection();
      // showUpgradeMessaging = false;
    }
    dispatch({ type: 'TOGGLE_PAGE_SECTIONS_OPTIONS_POPOVER', data: { showing, showUpgradeMessaging, offset, node, section } });
  }
};
/*** PAGE SECTIONS ***/

export const toggleWebsiteSectionOptionsPopover = ({ showing, section, offset, renaming }) => {
  return (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_WEBSITE_SECTION_OPTIONS_POPOVER', data: { showing, section, offset, renaming } });
  }
};

export const toggleWebsiteSectionNewButton = ({ showing, section, offset }) => {
  return (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_WEBSITE_SECTION_NEW_BUTTON', data: { showing, section, offset } });
  }
};

export const updateSitemapThumbnail = () => {
  return async (dispatch, getState) => {
    const { sitemap } = getState()
    // continue or not
    const canEdit = getCanEditInEditor();
    if (!canEdit) return;
    // in right format
    if (!sitemap?.format?.startsWith('tree-vertical')) return;
    dispatch({
      type: 'UPDATE_SITEMAP_THUMBNAIL',
      async payload() {
        const thumbnail = await exportThumb();
        return { thumbnail };
      }
    }).catch(e => {
      console.error(e);
      return { error: e };
    });
  }
};

/*** PAGE CONTENT SECTIONS ***/
/* export const savePageContentSection = ({ data, pageId, sectionId }) => {
  return (dispatch, getState) => {
    const firestore = getFirestore();
    dispatch({
      type: 'SAVE_PAGE_CONTENT_SECTION',
      async payload() {
        const { sitemap, user } = getState();
        await firestore.doc(`sitemaps/${sitemap?.id}/data/pages/content/${pageId}`).set({ lastEdit: user.id, data: { content: { [sectionId]: { data, lastEdit: user.id } } } }, { merge: true });
      }
    }).catch(e => {
      console.error(e);
    });
  };
};
 
export const initPageContent = ({ pageId, doc }) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'INIT_PAGE_CONTENT',
      async payload() {
        const data = { ...doc }.data;
        var sectionIds = keys(data.content);
        data.sections = [...sectionIds].map(id => {
          const section = data.content[id];
          section.id = id;
          section.data = JSON.parse(section.data);
          return section;
        });
        delete data.content;
        return { data, pageId }
      }
    }).catch(e => {
      console.error(e);
    });
  };
};
 
// No autosave here (as in page drawer not in main canvas)
export const addPageContentSection = ({ data, pageId, sectionId }) => {
 
  return (dispatch, getState) => {
    const firestore = getFirestore();
    const { sitemap, user } = getState();
 
    const index = Math.max.apply(Math, sitemap?.content.sections.map((o) => o.index)) + 1;
    const newSection = { id: sectionId, lastEdit: user.id, data, index };
    const pageSection = { [pageId]: [...sitemap?.data.page_sections [pageId], { id: sectionId index }] };
 
    dispatch({
      meta: { newSection, pageSection },
      type: 'ADD_PAGE_CONTENT_SECTION',
      async payload() {
        // update content doc (disabled for now)
        // await firestore.doc(`sitemaps/${sitemap?.id}/data/pages/content/${pageId}`).set({ lastEdit: user.id, data: { content: { [sectionId]: { data, index, lastEdit: user.id } } } }, { merge: true });
        // update page sections doc
        await firestore.doc(`sitemaps/${sitemap?.id}/data/sections`).set({ lastEdit: user.id, pages: { [pageId]: { [sectionId]: { index } } } }, { merge: true });
      }
    })
      .then(() => update())
      .catch(e => {
        console.error(e);
      });
  };
 
};
 
// No autosave here (as in page drawer not in main canvas)
export const updatePageContentSectionIndexes = (sections, pageId) => {
 
  return (dispatch, getState) => {
    const { sitemap } = getState();
 
    var updatedPageSection = [...sitemap?.data.page_sections [pageId]].map(section => {
      var contentSection = find(sections, (s) => s.id === section.id);
      if (contentSection) section.index = contentSection.index;
      return section;
    });
 
    dispatch({
      meta: { sections, pageId, updatedPageSection },
      type: 'UPDATE_PAGE_CONTENT_SECTION_INDEXES',
      async payload() {
      }
    })
      .then(() => update())
      .catch(e => {
        console.error(e);
      });
  };
 
}; */
/*** PAGE CONTENT SECTIONS ***/

export const saveSeoChange = (text, key) => {
  return (dispatch, getState) => {
    const state = getState();
    const firestore = getFirestore();
    const { sitemap, user } = state;
    const { seo } = sitemap?.data;
    const { page } = sitemap?.ui.PageDrawer;
    const pageData = seo[page.id] ? { ...seo[page.id] } : {}
    text = encodeURIComponent(text); // so no commas etc are saved to firestore
    if (pageData[key] === text) return // nothing has changed - don't continue
    dispatch({
      type: 'SAVE_SEO_CHANGE',
      async payload() {
        const pages = whitelistSEOKeys({ [page.id]: { [key]: text } })
        // save changes to firestore (if logged in)
        if (user.id) await firestore.doc(`sitemaps/${sitemap?.id}/data/seo`).set({ lastEdit: user.id, pages, updatedAt: new Date() }, { merge: true }) // merge - this way, if we were unable to save the data to the storage json in changes function for whatever reason, the changes will still stay in the doc to merged with the next edit
        // send to GA
        const gaObj = { [`ga_event`]: { category: "Sitemap Interactions", action: `SEO: Changed ${key} ` } };
        window.dataLayer.push({ event: 'generic_ga_event', ...gaObj });
        // return payload data
        return { seo: { ...seo, [page.id]: { ...seo[page.id], [key]: text } } };
      },
    }).catch(error => { console.error(error); return error });
    // whitelist pages keys function
    function whitelistSEOKeys(obj) {
      const SEOKeys = ["title", "description", "h1", "keywords"];
      const o = copy(obj);
      keys(o).forEach(pageId => {
        var data = o[pageId];
        keys(data).forEach(k => {
          if (!SEOKeys.includes(k)) delete data[k];
        });
      })
      return o;
    }
  };
};

export const togglePageButtons = (props) => {
  return (dispatch, getState) => {

    dispatch({ type: 'TOGGLE_PAGE_BUTTONS', ...props })

    if (!props.showing) resetMouseoverListeners({ onlyResetNode: true })

    setTimeout(() => { render() }, 0); // stops flash of page buttons with no background when no longer showing 

  };
};

export const toggleExportCoversProgressModal = ({ showing, forThumbnail, cancelled }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const { sitemap } = getState();
    const inUserFlow = getInUserFlow()
    const isLinkedToSitemap = getIsUserFlowLinkedToSitemap()
    // dispatch
    dispatch({ type: 'TOGGLE_EXPORT_COVERS_PROGRESS_MODAL', showing: !isLinkedToSitemap && !sitemap?.showCovers ? false : showing, forThumbnail, cancelled });
    // if (cancelled && ExportPDFDrawer.showing) dispatch(hideExportPDFDrawer());
  };
};

export const updateExportCoversProgressModalWithProgress = ({ progress }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // dispatch
    dispatch({ type: 'UPDATE_EXPORT_MODAL_WITH_PROGRESS', progress });
  };
};

export const whitelistPagesKeys = (obj, opts = {}) => {
  if (!obj) return;
  const { pagesSavedInFirestore } = opts;
  const firestore = getFirestore()
  const pagesKeys = ["index", "name", "parent", "url", "files", "pallette", "type", "website_section", "_delegate"]; // _delegate is delete page
  const o = copy(obj);
  // ensure only certain keys are present
  keys(o).forEach(pageId => {
    var data = o[pageId];
    // delete if page is undefined and pages are saving in firestore
    // we want undefined pages to go through if pages are saved in cloud storage
    if (pagesSavedInFirestore && (!data || isEmpty(data))) {
      delete o[pageId];
    } else if (data) {
      // ensure parent is included with all pages, if not deleting page (so we don't get multiple roots when parent is missing)
      if (pageId !== 'home') {
        const deletingPage = keys(data).includes('_delegate');
        if (!deletingPage) {
          const pagesDoc = getSitemap()?.docs?.pages;
          // don't continue if already providing parent in update
          const updateIncludesParent = keys(data).includes('parent');
          if (pagesDoc[pageId] && !updateIncludesParent) {
            // check if page has parent and page's parent exists in doc
            data.parent = (pagesDoc[pageId].parent && pagesDoc[pagesDoc[pageId].parent]) ? pagesDoc[pageId].parent : (pagesDoc[pageId].website_section ? pagesDoc[pageId].website_section : "home");
          }
        } else {
          o[pageId] = pagesSavedInFirestore ? firestore.FieldValue.delete() : null;
        }
      }
      // continue cleaning
      keys(data).forEach(k => {
        if (!pagesKeys.includes(k)) delete data[k];
        // ensure we're setting null instead of FieldValue.delete if saving in storage
        if (!pagesSavedInFirestore && data[k] && keys(data[k]).includes('_delegate')) data[k] = null;
        // set null values to delete if saved in firestore]
        if (isNull(data[k])) { data[k] = pagesSavedInFirestore ? firestore.FieldValue.delete() : null; }
      });
    };
    // end of loop
  })
  return o;
}

export const whitelistWebsiteSectionKeys = (obj) => {
  const websiteSectionKeys = ["index", "title", "_delegate"]; // _delegate is delete
  const o = copy(obj);
  keys(o).forEach(pageId => {
    var data = o[pageId];
    keys(data).forEach(k => {
      if (!websiteSectionKeys.includes(k)) delete data[k];
    });
  })
  return o;
}

export const whitelistPageSectionKeys = (obj) => {
  const pageSectionKeys = ["index", "title", "color", "wireframe", "_delegate"]; // _delegate is delete
  const o = copy(obj);
  keys(o).forEach(pageId => {
    var data = o[pageId];
    keys(data).forEach(sectionId => {
      var section = data[sectionId];
      keys(section).forEach(k => {
        if (!pageSectionKeys.includes(k)) delete section[k];
      })
    });
  })
  return o;
}