// eslint-disable-line no-unused-vars
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore'
import reduxPromiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';

export default [
  reduxPromiseMiddleware(), // Resolve, reject promises with conditional optimistic updates
  thunk.withExtraArgument({ getFirebase, getFirestore }), // Thunk middleware for Redux
];
