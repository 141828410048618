import { center, initZoom } from '../../screens/Sitemap/app/canvas/utils/zoom';
import { cloneDeep, groupBy, isEmpty, isNumber, keys, map, spread, union } from 'lodash';
import { defaultPalletteColors, getUserId } from '../../helpers';
import { deleteField, doc, getFirestore, setDoc } from "firebase/firestore"
import { getDataDoc, getDataDocsFromFirestoreListener, initSitemap } from './sitemap-actions';
import { renderListeners, resetMouseoverListeners } from '../../screens/Sitemap/app/canvas/utils/listeners';

import copy from 'fast-copy';
import deepmerge from 'deepmerge';
import { getCanEditInEditor } from '../../screens/Editor/Navbar/helpers';
import { interactionsCanvas } from '../../screens/Sitemap/app/canvas/canvases';
import { mergeFileChange } from './files-actions';
import { render as renderUserFlow } from '../../screens/Sitemap/user-flows/render';
import { update as updateUserFlow } from '../../screens/Sitemap/user-flows/helpers';
import { wait } from '../../../shared/helpers';

export const initUserFlow = (doc) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'INIT_USER_FLOW',
            meta: { ...doc },
            async payload() {
                // get sitemap if linked
                if (doc.sitemap) {
                    dispatch(initSitemap({ id: doc.sitemap }, { forUserFlow: true })) // false = new sitemap 
                    // await wait(2000);
                }
            },
        }).then(async () => {
            // check for old data
            if (!isEmpty(doc?.docs?.elements)) {
                await moveDataToElementsDoc(doc.id, doc?.docs?.elements)
            }
            // continue
            const { elements } = getDataDocsFromFirestoreListener({ flow: { id: doc.id } }) || (doc?.docs || {});
            // 
            dispatch(initUserFlowElementsDoc(elements))
        }).catch(e => { console.error(e); return { error: e } });
    };
};

const moveDataToElementsDoc = async (docId, elements) => {
    const canEdit = getCanEditInEditor()
    if (!canEdit) return console.log("need to migrate user flow, but don't have permission");
    try {
        console.log('trying to move data elements')
        const userId = getUserId()
        const firestore = getFirestore()
        // doc ref
        const docRef = doc(firestore, "user-flows", docId)
        // elements ref
        const elementsRef = doc(docRef, "data", "elements")
        // set elements ref
        await setDoc(elementsRef, { elements, lastEdit: userId, updatedAt: new Date() }, { merge: true })
        // if set elements successfully, clear data field from doc
        await setDoc(docRef, { data: deleteField(), updatedBy: userId, updatedAt: new Date() }, { merge: true })
        // success
        console.log('migrated successfully')
    } catch (e) {
        console.error(e);
        // throw new Error(e);
    }
}

export const initUserFlowElementsDoc = (docData) => {
    return (dispatch, getState) => {
        const state = getState();
        const { flow } = state;
        if (flow?.loaded && !isEmpty(flow?.docs?.elements)) return // only initialize elements once
        // dispatch
        dispatch({
            type: 'INIT_USER_FLOW_ELEMENTS_DOC',
            async payload() {
                const doc = await getDataDoc({ doc: 'elements', docData, flow });
                let elements = doc.elements;
                // return
                return {
                    doc,
                    docs: {
                        elements,
                        filesystem: {
                            ...flow?.docs.filesystem,
                            elements: !doc.file ? 'firestore' : 'storage'
                        }
                    },
                };
            },
        }).then(() => {
            dispatch(setUserFlowToLoaded())
        }).catch(e => { console.error(e); return { error: e } });

    };
};

export const setUserFlowToLoaded = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_USER_FLOW_TO_LOADED' });
        updateUserFlow()
    };
};

export const draggingNewSymbol = ({ dragging, symbol }) => {
    return (dispatch, getState) => {
        dispatch({ type: 'DRAGGING_NEW_SYMBOL', dragging, symbol });
        if (!dragging) interactionsCanvas.clear();
    };
};

export const insertNewSymbol = (newData) => {
    return (dispatch, getState) => {
        const { flow } = getState();
        const data = deepmerge(flow.data, newData);
        dispatch({ type: 'INSERT_NEW_SYMBOL', data });
        updateUserFlow();
    };
};

export const changedSymbol = (newData) => {
    return (dispatch, getState) => {
        const { flow } = getState();
        const data = deepmerge(flow.data, newData);
        dispatch({ type: 'CHANGED_SYMBOL', data });
        updateUserFlow();
    };
};

export const changedConnector = (data) => {
    return (dispatch, getState) => {
        const { flow } = getState();
        data = deepmerge(flow.data, data);
        dispatch({ type: 'CHANGED_CONNECTOR', data });
        updateUserFlow();
    };
};

/*** ADD CHANGE ***/
export const addFlowChange = props => {
    const { change, history } = props;
    return async (dispatch, getState) => {
        dispatch({ type: 'ADD_FLOW_CHANGE', change, history });
    }
};
/*** ADD CHANGE ***/


export const saveFlowElementsChanges = () => {

    return async (dispatch, getState, { getFirestore }) => {

        const firestore = getFirestore();
        const { user, flow } = getState();

        const changes = flow.changes;
        if (changes.saving || isEmpty(changes.data)) return false;
        // so we know which changes to delete when saved to firestore successfully
        const changeIds = map(changes.data, 'id');
        //
        dispatch({
            type: 'SAVE_FLOW_ELEMENTS_CHANGES',
            async payload() {

                // join changes into one array
                var data = cloneDeep(spread(union)(map(changes.data, 'data')));

                const grouped = groupBy(data, 'id');
                const ids = keys(grouped);

                let firestoreData = {};

                ids.forEach(id => {

                    // get latest data from user flow map
                    var data = copy(flow?.docs?.elements?.[id])

                    /*** modify nodes ***/
                    // delete symbol/page
                    if (isEmpty(data)) return firestoreData[id] = firestore.FieldValue.delete();
                    // ensure we are not saving undefined/empty values
                    Object.keys(data).forEach((key) => {
                        // ensures 0 values aren't set to delete
                        if (!data[key] && !isNumber(data[key])) data[key] = firestore.FieldValue.delete();
                        // delete empty connectors
                        if (key === 'connectors') { if (isEmpty(data[key])) data[key] = firestore.FieldValue.delete(); }
                    });
                    // check if unlinked from page
                    if (data.page === null) data.page = firestore.FieldValue.delete();
                    // check if color for symbol is default
                    if (defaultPalletteColors.includes(data.color)) data.color = firestore.FieldValue.delete();
                    /*** modify nodes ***/

                    /*** modify connectors ***/
                    if (data.connectors) {
                        const positions = keys(data.connectors);
                        if (positions) {
                            positions.forEach(pos => {
                                var position = data.connectors[pos];
                                // delete whole connector
                                if (isEmpty(position)) data.connectors[pos] = firestore.FieldValue.delete();
                                keys(position).forEach(id => {
                                    // delete specific position
                                    if (!position[id]) data.connectors[pos][id] = firestore.FieldValue.delete();
                                    // loop through attributes and delete any null fields
                                    keys(position[id]).forEach(key => {
                                        if (!position[id][key]) position[id][key] = firestore.FieldValue.delete();
                                    })
                                    if (defaultPalletteColors.includes(position[id].color)) data.connectors[pos][id].color = firestore.FieldValue.delete();
                                    if (position[id].index === 0) data.connectors[pos][id].index = firestore.FieldValue.delete();
                                })
                            })
                        }
                    }
                    /*** modify connectors ***/
                    firestoreData[id] = { ...firestoreData[id], ...data };
                });

                // whitelist keys
                firestoreData = whitelistElementsKeys(firestoreData);

                /*** save page data ***/
                if (!isEmpty(firestoreData)) {
                    // if (import.meta.env.PROD) {
                    await firestore.doc(`user-flows/${flow.id}/data/elements`).set({ elements: firestoreData, lastEdit: user.id, updatedAt: new Date() }, { merge: true });
                    // }
                }
                /*** save page data ***/
            }
        }).then(() => {
            dispatch(clearSavedFlowChanges(changeIds));
        }).catch(e => {
            console.error(e);
            return { error: e }
        });
    }
};

export const whitelistElementsKeys = (obj) => {
    const elementsKeys = ['type', 'page', 'text', 'connectors', 'wireframe', 'x', 'y', 'index', 'color', 'device', '_delegate']; // _delegate is delete
    const o = copy(obj);
    keys(o).forEach(pageId => {
        var data = o[pageId];
        keys(data).forEach(k => {
            if (!elementsKeys.includes(k)) delete data[k];
        });
    })
    return o;
}

export const changeLinkedSitemap = (sitemap) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();
        const { user, flow } = getState();
        const collectionId = "user-flows"
        dispatch(mergeFileChange(flow.id, collectionId, { sitemap }))
        dispatch({
            type: 'CHANGE_LINKED_SITEMAP',
            async payload() {
                await firestore.doc(`${collectionId}/${flow.id}`).set({ sitemap: (sitemap || firestore.FieldValue.delete()), updatedBy: user.id, updatedAt: new Date() }, { merge: true });
                return { sitemap }
            }
        }).then(() => {
            if (sitemap) dispatch(initSitemap({ id: sitemap }, { forUserFlow: true }))
        }).catch(e => {
            console.error(e);
            return { error: e }
        });
    }
};

export const clean = (object) => {
    Object
        .entries(object)
        .forEach(([k, v]) => {
            if (v && typeof v === 'object') {
                clean(v);
            }
            // eslint-disable-next-line
            if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined || v === "") {
                if (Array.isArray(object)) {
                    object.splice(k, 1);
                } else {
                    delete object[k];
                }
            }
        });
    return object;
}

export const clearSavedFlowChanges = savedChanges => {
    return async dispatch => {
        dispatch({ type: 'CLEAR_SAVED_FLOW_CHANGES', savedChanges });
    };
};

/*** UNDO/REDO CHANGES ***/
export const undoFlowsUserChange = props => {
    return (dispatch, getState) => {
        const state = getState();
        //
        const undo = cloneDeep(state.flow.history.undo);
        const redo = cloneDeep(state.flow.history.redo);
        //
        const removedItem = undo.pop();
        //
        redo.push(removedItem);
        //
        dispatch({ type: 'UNDO_FLOW_USER_CHANGE', undo, redo });
    };
};

export const redoFlowsUserChange = props => {
    return (dispatch, getState) => {
        const state = getState();
        //
        const undo = cloneDeep(state.flow.history.undo);
        const redo = cloneDeep(state.flow.history.redo);
        //
        const undoedItem = redo.pop();
        //
        undo.push(undoedItem);
        //
        dispatch({ type: 'REDO_FLOW_USER_CHANGE', undo, redo });
    };
};
/*** UNDO/REDO CHANGES ***/

// used for revision history
export const overrideFlowData = (data) => {
    return (dispatch, getState) => {
        dispatch({ type: 'OVERRIDE_FLOW_DATA', data });
        // update and load!
        updateUserFlow();
        // center
        // center();
    }
};

export const showFlowsOptionsPopover = props => {
    const { nodes, links, offset, newSymbolOptions, symbolOptions, connectorOptions } = props;
    return (dispatch, getState) => {
        dispatch({ type: 'SHOW_FLOW_OPTIONS_POPOVER', nodes, links, offset, newSymbolOptions, symbolOptions, connectorOptions });
    };
};

export const hideFlowsOptionsPopover = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'HIDE_FLOW_OPTIONS_POPOVER' });
    }
};

export const mouseoverSymbol = (symbol) => {
    return (dispatch, getState) => {
        dispatch({ type: 'MOUSEOVER_SYMBOL', symbol });
    }
};

export const mouseoverConnector = (connector) => {
    return (dispatch, getState) => {
        dispatch({ type: 'MOUSEOVER_CONNECTOR', connector });
        renderUserFlow();
    }
};

export const mergeFlowEdits = data => {
    return async (dispatch, getState) => {

        const { flow } = getState();
        // continue
        let elements = data.elements ? deepmerge(flow?.docs?.elements, data?.elements) : flow?.docs.elements;
        keys(data?.elements).forEach(key => data?.elements?.[key] === undefined ? delete elements[key] : {}); // remove undefined (deleted) elements

        dispatch({ type: 'MERGE_FLOW_EDITS', elements });

        updateUserFlow();
    };
};

export const showingNewConnectorLine = (props) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SHOWING_NEW_CONNECTOR_LINE', props });
        renderUserFlow()
    }
};

export const showingMultiSelectBox = (props) => {
    return (dispatch, getState) => {
        dispatch({ type: 'SHOWING_MULTI_SELECT_BOX', props });
        renderUserFlow()
    }
};

export const mouseoverIcon = (mouseover) => {
    return (dispatch, getState) => dispatch({ type: 'MOUSEOVER_ICON', mouseover });
};

export const setUserFlowSymbolsAndConnectors = ({ nodes, links }) => {
    return (dispatch, getState) => {
        const { flow } = getState();
        nodes = clean(nodes);
        // nodes.forEach(d => flow.data[d.id] = d)
        dispatch({ type: 'SET_USER_FLOW_SYMBOLS_AND_CONNECTORS', nodes, links: links || flow.data.links });
        renderUserFlow(nodes, links)
    };
    function clean(nodes) {
        // ensure x and y are always floats - REALLY IMPORTANT
        // ensure sections are only if linked page-sections
        return nodes.map(node => ({ ...node, x: +node.x, y: +node.y, sections: node.type === 'page-sections' && node.sections }));
    }
};

export const toggleUserFlowSymbolButtons = (props) => {
    return (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_USER_FLOW_SYMBOL_BUTTONS', ...props })
        if (!props.showing) resetMouseoverListeners()
        renderUserFlow()
    };
};