import React, { useEffect } from 'react';
import { SharedCanvas, init } from '../app/canvas/shared';
import { useGetSitemap, useGetUserFlow, usePrevious } from '../../../hooks';

import { SymbolButtons } from './components/mouseover/buttons';
import { UserFlowsOptionsPopover } from './Options';
import { setUserFlowToLoaded } from '../../../store/actions/flow-actions';
import { useDispatch } from 'react-redux';

export const Canvas = () => {

    const flow = useGetUserFlow()

    InitUserFlow(flow)

    return (
        <SharedCanvas>
            {flow?.ui?.SymbolButtons?.showing && !flow?.ui?.SymbolButtons?.zooming && <SymbolButtons />}
            <UserFlowsOptionsPopover />
        </SharedCanvas>
    )
}

// IMPORTANT - HANDLES LOADING USER FLOW
const InitUserFlow = (flow) => {

    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const prevSitemap = usePrevious(sitemap)

    const prevFlow = usePrevious(flow)

    // on update
    useEffect(() => {
        const linkedSitemap = flow?.sitemap
        const justLoadedSitemap = sitemap?.loaded && !prevSitemap?.loaded
        // ensure user flow is only loaded when linked sitemap has
        if (linkedSitemap && justLoadedSitemap) dispatch(setUserFlowToLoaded())
        // if not linked sitemap, will setUserFlowToLoaded in initUserFlowElementsAction
        if (flow?.loaded && !prevFlow?.loaded) init()
    }, [sitemap, prevSitemap, flow, prevFlow])

}