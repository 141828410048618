import { compact, find, isEmpty, join, orderBy } from 'lodash'
// // import amplitude from 'amplitude-js/amplitude';
import { expandToNode, getPageName, update } from '../../../Sitemap/utils/app';

import { centerNode } from '../../../Sitemap/app/canvas/utils/zoom';
import { toTitleCase } from '../../../../helpers';

// { index: 0, id: 'y18zz1etzb', hierarchy: 'Home >', name: 'Advanced_image_search', children: null }
export const populateSearchData = ({ isOpen, searchData, setSearchData, filteredSearchData, setFilteredSearchData, shouldShowPages, sitemap, flow }) => {
    const inUserFlow = flow?.id;
    if (isOpen) {
        if (isEmpty(searchData)) {
            let options = []
            if (!inUserFlow) {
                const { root, section: subfolder, website_sections } = sitemap?.data || {}
                options = root ? getArrayOfPages(subfolder ? subfolder : root, website_sections, sitemap) : [];
            } else {
                options = [...flow.data.nodes].map(f => {
                    const typeLabel = f?.type?.startsWith('page') ? `${f?.type === 'page' ? "Linked" : ""} Page` : toTitleCase(splitCamelCase(f.type))
                    return {
                        id: f.id,
                        name: f.name || f.text,
                        type: f.type,
                        typeLabel,
                        hierarchy: '',
                        children: null
                    }
                })
            }
            setSearchData(options)
            if (shouldShowPages) setFilteredSearchData(options) // should load pages immediately
        }
    } else {
        if (!isEmpty(searchData)) setSearchData([])
        if (!isEmpty(filteredSearchData)) setFilteredSearchData([])
    }
    return null
}

function splitCamelCase(s) {
    return (s || '').split(/(?=[A-Z])/).join(' / ');
}

function getArrayOfPages(root, website_sections, sitemap) {
    var nodes = [];
    function recurse(node, section) {
        if (!node) return;
        if (!node.name) return;
        const websiteSectionData = section ? website_sections[section.website_section] : null;
        if (!node.overflow) {
            if (node.parent) {
                const children = node._children || node.children;
                nodes.push({
                    index: !section || !websiteSectionData ? 0 : websiteSectionData.index + 1,
                    id: node.id,
                    hierarchy: getPageNameHierarchy(node, sitemap),
                    name: getPageName(node.name),
                    children
                });
            }
        };
        var children = node._children ? node._children : node.children;
        children?.forEach((n => recurse(n, section ? section : null)));
    }
    //
    recurse(root, null);
    // website sections
    root.website_sections?.forEach(s => recurse(s, s));
    // sort main nodes by hierarchy
    nodes = orderBy(nodes, ['index', 'hierarchy']);
    //
    return nodes;
};

const getPageNameHierarchy = (node, sitemap) => {
    let arr = [];
    function recurse(n) {
        arr.unshift(getName(n));
        if (sitemap?.docs.pages[n.parent]) return recurse(sitemap?.docs.pages[n.parent]);
        return `${join(compact(arr), " > ")} >`;
    }
    function getName(n) {
        return `${sitemap?.docs.pages[n.parent] ? sitemap?.docs.pages[n.parent].name : (n.website_section && sitemap?.docs.website_sections[n.website_section] ? sitemap?.docs.website_sections[n.website_section].title : '')}`
    }
    return recurse(sitemap?.docs.pages[node.id]);
}

// basically a way to get the path to an object
export const searchTree = (root, search) => {
    let path = [];
    function searchFunc(node) {
        if (node?.id === search) { // if search is found return, add the object to the path and return it
            path.push(node);
            return path;
        } else if (node.children || node._children) { // if children are collapsed d3 object will have them instantiated as _children
            var children = (node.children) ? node.children : node._children;
            for (var i = 0; i < children.length; i++) {
                path.push(node); // we assume this path is the right one
                var found = searchFunc(children[i]);
                if (found) { return found; } else { path.pop(); }
            }
        } else { // not the right object, return false so it will continue to iterate in the loop
            return false;
        }
    }
    let found = searchFunc(root);
    // not found in main pages
    if (!found) {
        // website sections
        root.website_sections?.forEach(s => {
            const didFind = searchFunc(s);
            if (didFind) { found = didFind; return; }
        });
    }
    return found;
};

export const goToPage = ({ selected, nodes, root, onClose }) => {
    // close search modal
    onClose();
    // amplitude tracking
    // amplitude.getInstance().logEvent('SITEMAP_SEARCHED_GO_TO_PAGE');
    // node not showing (aka in _children not in dom)
    var node = find(nodes, (d) => { return d.id === selected.id });
    /*** node isn't visible on page (i.e collapsed) ***/
    if (!node) {
        var paths = searchTree(root, selected.id);
        if (typeof (paths) !== "undefined") return expandToNode(paths, { searched: true });
    }
    /*** node isn't visible on page (i.e collapsed) ***/
    /*** node is visible on page, can go straight to it ***/
    if (node) {
        node.searched = true;
        update();
    }
    /*** node is visible on page, can go straight to it ***/
}

export const goToElement = ({ selected, nodes, onClose }) => {
    // close search modal
    onClose();
    // amplitude tracking
    // amplitude.getInstance().logEvent('USERFLOW_SEARCHED_GO_TO_ELEMENT');
    // node not showing (aka in _children not in dom)
    var node = find(nodes, (d) => { return d.id === selected.id });
    // go to node
    node.searched = true;
    // update
    centerNode(node);
}