import { getInOrganization, getOrganizationId, getUser, getUserId } from '../../helpers';

import { getFolders } from './folders-actions';
import { getOrganizations } from './organizations-actions';
import { isInProd } from '../../../shared/helpers';
import { setOrganization } from './organization-actions';

export const LogInWithEmailAndPassword = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: 'LOGIN',
      async payload() {
        await firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password);
      },
    }).catch(e => {
      console.error(e);
    });
  };
};

export const signUpWithEmailAndPassword = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({
      type: 'SIGNUP',
      async payload() {
        // sign up user using authentication service
        const signedUpUser = await firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password);
        // push new user signup event to GTM
        window.dataLayer.push({ 'event': 'new_user_signup' });
        // Set user profile
        await firestore.doc(`users/${signedUpUser.user.uid}`)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
            photoURL: null,
            organizations: [],
            refreshToken: false
          });
        /*** amplitude tracking ***/
        // amplitude.getInstance().init(import.meta.env.VITE__AMPLITUDE); // init amplitude
        // amplitude.getInstance().setUserId(signedUpUser.user.uid);
        // amplitude.getInstance().logEvent('NEW_USER_SIGNUP');
        /*** amplitude tracking ***/
        return { uid: signedUpUser.user.uid };
      },
    }).catch(e => {
      console.error(e);
    });
  };
};

export const signInWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({
      type: 'SIGNUP',
      async payload() {

        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().useDeviceLanguage();

        const signedUpUser = await firebase.auth().signInWithPopup(provider)

        const { isNewUser, profile } = signedUpUser?.additionalUserInfo || {}

        if (isNewUser) {
          var data = {};
          data.firstName = profile?.given_name
          data.lastName = profile?.family_name
          data.email = profile?.email
          data.organizations = []
          data.refreshToken = false
          // set photoURL regardless
          data.photoURL = profile.picture
          // set transaction
          // Create a reference to the user doc.
          var userDocRef = firestore.doc(`users/${signedUpUser?.user?.uid}`);
          await userDocRef.set(data, { merge: true });
        }
      },
    }).catch(e => {
      console.error(e);
    });
  };
};

export const redirectUser = path => {
  return (dispatch, getState) => {
    dispatch({ type: 'REDIRECT_USER', path })
  }
}

function noop() { }

export const initAccount = ({ user, forTokenRefresh }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({
      type: 'INIT_ACCOUNT',
      async payload() {
        // set user id in Tag Manager
        window.dataLayer.push({ 'event': 'initAccount', 'userId': user.id, user: { id: user.id, email: user.email } });
        // always refresh and retrieve token
        const { claims } = user.token;
        /*** force re-initialization if needed (forced token refresh) ***/
        const shouldRefresh = forTokenRefresh || claims?.organization === undefined
        if (user && shouldRefresh) {
          return await firebase
            .functions()
            .httpsCallable('auth-initAccount')({}) // IMPORTANT: need to send empty object through for function to work
            .then(async result => {
              const { success } = result.data;
              if (!success) throw new Error("Couldn't init account");
              // refresh and retrieve token
              const { currentUser } = firebase.auth();
              await currentUser.getIdToken(true);
              // set refreshToken to false
              await firestore.doc(`users/${user.id}`).set({ refreshToken: false }, { merge: true }).catch(e => console.error(e));
              //
              const IdTokenResult = await currentUser.getIdTokenResult();
              // refresh token in redux
              dispatch({ type: 'REFRESHED_TOKEN', payload: IdTokenResult });
              // return
              return { claims: IdTokenResult.claims };
            }).catch(e => console.error(e));
        } else {
          // no re-initialization needed, pass through claims
          return { claims };
        }
      },
    }).then(async props => {
      // return here if for token refresh
      if (forTokenRefresh) return;
      // continue if first initing account
      const { claims } = props?.value || {};
      dispatch(getFolders({ claims }));
      // organization
      if (claims?.organization) dispatch(setOrganization(claims));
      // get folders
      dispatch(getOrganizations())
      // init profitwell (production only)
      if (import.meta.env.PROD && !claims.organization) window.dataLayer.push({ 'event': 'start_pw' });
      // set user id in amplitude
      // if (user.id) amplitude.getInstance().setUserId(user.id);
    }).catch(error => {
      console.error(error)
      return { error };
    });
  };
};

export const switchAccount = (id) => {
  return (dispatch, getState, { getFirebase }) => {
    const userId = getUserId()
    const inOrganization = getInOrganization()
    const organizationId = getOrganizationId()
    if ((!inOrganization && userId === id) || (inOrganization && organizationId === id)) return;
    // continue
    dispatch({
      type: 'SWITCH_ACCOUNT',
      async payload() {
        const firebase = getFirebase()
        //
        const switched = await firebase.functions().httpsCallable('auth-switchAccount')({ id });
        //
        const { success } = switched.data;
        if (!success) throw new Error("Couldn't switch account");
        // refresh and retrieve token
        const user = firebase.auth().currentUser;
        await user.getIdToken(true);
        const IdTokenResult = await user.getIdTokenResult();
        const { claims } = IdTokenResult;
        // organization
        if (claims.organization) dispatch(setOrganization(claims));
        return { claims };
      },
    }).then(({ value }) => {
      dispatch(getFolders({ claims: value.claims }))
    }).catch(e => {
      console.error(e);
    });
  };
};

export const switchTeam = (id, history) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    // console.log(`Switching team token to ${id}`)
    dispatch({
      type: 'SWITCH_TEAM',
      meta: { teamId: id },
      async payload() {

        await firebase.functions().httpsCallable('auth-switchTeam')({ id });
        // refresh and retrieve token
        const user = firebase.auth().currentUser;
        await user.getIdToken(true);
        const IdTokenResult = await user.getIdTokenResult();
        // console.log(IdTokenResult.claims);
        return { claims: IdTokenResult.claims };
      },
    }).catch(e => {
      console.error(e);
    })
  };
};

export const logOut = (history) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    dispatch({
      type: 'LOGOUT',
      async payload() {
        // log out
        await firebase.logout();
        //
        if (history) history.push('/app/login');
      },
    });
  };
};
